import React, { Component } from "react";
import PropTypes from "prop-types";
import { withNamespaces, Trans } from "react-i18next";
import { Link, withRouter, Redirect } from "react-router-dom";
import { Collapse, Badge } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions/actions";
import SidebarRun from "./Sidebar.run";
import SidebarUserBlock from "./SidebarUserBlock";
import base64url from "base64url";

class Sidebar extends Component {
  state = {
    redirect:false,
    collapse: {},
    userMenu: []
  };

  getDecodedID = (pathID) => {
    //var uri = encodeURIComponent(data);
    var uri = base64url.decode(pathID)// + data.toString());
    
    if(uri != null && uri.length > 10 ) {
        const id = uri.substring(10);
        return id;
    }else{
        this.setState({
            logoutUser: true
        });
        return 0;
    }
  }

  getEncodedUrl = (data) => {
      console.log(data)
      //var uri = encodeURIComponent(data);
      var uri = base64url(this.getRandomString(10) + data.toString());
      return uri;
  }

  getRandomString = (length) => {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
  }
  
  componentDidMount() {

    if(this.props.token.length > 0 && this.props.token != null) {
      
      const Menu = [];
      let modules = [];
      
      // NTRC MENU
      const ntrSubmenu = [];
      ntrSubmenu.push(
		{
	       name: "Collection",
	       translate: "sidebar.nav.MENU",
	       path: "/ntr/collection"
	    },
	    {
	       name: "Budget",
	       translate: "sidebar.nav.MENU",
	       path: "/ntr/budget"
	    },
	    {
	       name: "Billing",
	       translate: "sidebar.nav.MENU",
	       path: "/ntr/create_bill"
	    }
      );

      if(this.props.token[0].role_id === 1) {

        Menu.push({
          name: "DASHBOARD",
          icon: "icon-speedometer",
          path: "/mainDashboard"
        });
    
        Menu.push({
          name: "USER MGT.",
          icon: "icon-people",
          translate: "sidebar.nav.MENU",
          submenu: [
            {
              name: "System Users",
              translate: "sidebar.nav.SUBMENU",
              path: "/users/user"
            },
            {
              name: "Gov. Officials",
              translate: "sidebar.nav.SUBMENU",
              path: "/users/government-officials"
            },
            {
              name: "Roles",
              translate: "sidebar.nav.SUBMENU",
              path: "/users/roles"
            },
            {
              name: "Permissions",
              translate: "sidebar.nav.SUBMENU",
              path: "/users/permissions"
            },
            {
              name: "Modules",
              translate: "sidebar.nav.SUBMENU",
              path: "/users/modules"
            }
          ]
        });

        Menu.push({
          name: "SETTINGS",
          icon: "icon-settings",
          translate: "sidebar.nav.MENU",
          submenu: [
            {
              name: "General",
              translate: "sidebar.nav.SUBMENU",
              path: "/regulators"
            },
            {
              name: "PMS",
              translate: "sidebar.nav.SUBMENU",
              path: "/capitals"
            },
            {
              name: "BMS",
              translate: "sidebar.nav.SUBMENU",
              path: "/position"
            },
            {
              name: "FARS",
              translate: "sidebar.nav.SUBMENU",
              path: "/templates"
            }
          ]
        });

        Menu.push({
          name: "FARS",
          icon: "icon-briefcase",
          translate: "sidebar.nav.MENU",
          submenu: [
            {
              name: "Quarterly reports",
              translate: "sidebar.nav.MENU",
              path: "/fars/quarterly_statements"
            },
            {
              name: "Audited Reports",
              translate: "sidebar.nav.MENU",
              path: "/fars/audited_statements"
            },
            {
              name: "Analysis",
              translate: "sidebar.nav.MENU",
              path: "/fars/financial-analysis"
            }
          ]
        });

        const boardSubmenu = [];
        boardSubmenu.push({
            name: "Prospective Members",
            translate: "sidebar.nav.SUBMENU",
            path: "/appointee"
          },
          {
            name: "Board Members",
            translate: "sidebar.nav.SUBMENU",
            path: "/appointments"
          },
          {
            name: "Representatives",
            translate: "sidebar.nav.SUBMENU",
            path: "/representatives"
          },
          {
            name: "Repr. Reg-status",
            translate: "sidebar.nav.SUBMENU",
            path: "/rep_progress"
          },
          {
            name: "Member Reg-status",
            translate: "sidebar.nav.SUBMENU",
            path: "/appointmentProgress"
          }
        );

        Menu.push({
          name: "BMS",
          icon: "icon-briefcase",
          translate: "sidebar.nav.MENU",
          submenu: boardSubmenu
        });

        Menu.push({
          name: "NTRC",
          icon: "icon-briefcase",
          translate: "sidebar.nav.MENU",
          submenu: ntrSubmenu
        });

        const institutionSubmenu = [];
        institutionSubmenu.push(
          {
            name: "Registration",
            translate: "sidebar.nav.SUBMENU",
            path: "/piscs"
          },
          {
            name: "Management",
            translate: "sidebar.nav.SUBMENU",
            path: "/management"
          }
        );

        Menu.push({
          name: "PSC DETAILS",
          icon: "icon-list",
          translate: "sidebar.nav.MENU",
          submenu: institutionSubmenu
        });

        Menu.push({
          name: "REPORTS",
          icon: "icon-chart",
          translate: "sidebar.nav.MENU",
          submenu: [
            {
              name: "BMS",
              translate: "sidebar.nav.SUBMENU",
              path: "/boardManagement"
            },
            {
              name: "FARS",
              translate: "sidebar.nav.SUBMENU",
              path: "/financialManagement"
            },
            {
              name: "OTR Portifolio",
              translate: "sidebar.nav.SUBMENU",
              path: "/portifolioManagement"
            },
            {
              name: "PSC Management",
              translate: "sidebar.nav.SUBMENU",
              path: "/pscManagement"
            }
          ]
        });

        const archiveSubmenu = [];
        archiveSubmenu.push({
          name: "Board Members",
          translate: "sidebar.nav.SUBMENU",
          path: "/previousBoards"
        },{
          name: "Management",
          translate: "sidebar.nav.SUBMENU",
          path: "/previousManagement"
        },
        {
          name: "Documents",
          translate: "sidebar.nav.SUBMENU",
          path: "/documents"
        });

        //Menu.push({
        //  name: "ARCHIVE",
        //  icon: "icon-chart",
        //  translate: "sidebar.nav.MENU",
        //  submenu: archiveSubmenu
       // });

        this.setState({
          userMenu: Menu
        });

      }else if(this.props.token[0].role_name.toLowerCase() === "otr admin") {

        Menu.push({
          name: "DASHBOARD",
          icon: "icon-speedometer",
          path: "/mainDashboard"
        });

        Menu.push({
          name: "USER MGT.",
          icon: "icon-people",
          translate: "sidebar.nav.MENU",
          submenu: [
            {
              name: "System Users",
              translate: "sidebar.nav.SUBMENU",
              path: "/users/user"
            },
            {
              name: "Govn. Officials",
              translate: "sidebar.nav.SUBMENU",
              path: "/users/government-officials"
            }
          ]
        });

        Menu.push({
          name: "SETTINGS",
          icon: "icon-settings",
          translate: "sidebar.nav.MENU",
          submenu: [
            {
              name: "General",
              translate: "sidebar.nav.SUBMENU",
              path: "/regulators"
            },
            {
              name: "PMS",
              translate: "sidebar.nav.SUBMENU",
              path: "/capitals"
            },
            {
              name: "BMS",
              translate: "sidebar.nav.SUBMENU",
              path: "/position"
            },
            {
              name: "FARS",
              translate: "sidebar.nav.SUBMENU",
              path: "/templates"
            }
          ]
        });

        Menu.push({
          name: "FARS",
          icon: "icon-briefcase",
          translate: "sidebar.nav.MENU",
          submenu: [
            {
              name: "Quarterly reports",
              translate: "sidebar.nav.MENU",
              path: "/fars/quarterly_statements"
            },
            {
              name: "Audited Reports",
              translate: "sidebar.nav.MENU",
              path: "/fars/audited_statements"
            },
            {
              name: "Analysis",
              translate: "sidebar.nav.MENU",
              path: "/fars/financial-analysis"
            }
          ]
        });

        const boardSubmenu = [];
        boardSubmenu.push({
            name: "Prospective Members",
            translate: "sidebar.nav.SUBMENU",
            path: "/appointee"
          },
          {
            name: "Board Members",
            translate: "sidebar.nav.SUBMENU",
            path: "/appointments"
          },
          {
            name: "Representatives",
            translate: "sidebar.nav.SUBMENU",
            path: "/representatives"
          },
          {
            name: "Repr. Reg-status",
            translate: "sidebar.nav.SUBMENU",
            path: "/rep_progress"
          },
          {
            name: "Member Reg-status",
            translate: "sidebar.nav.SUBMENU",
            path: "/appointmentProgress"
          }
        );

        Menu.push({
          name: "BMS",
          icon: "icon-briefcase",
          translate: "sidebar.nav.MENU",
          submenu: boardSubmenu
        });

        Menu.push({
          name: "NTRC",
          icon: "icon-briefcase",
          translate: "sidebar.nav.MENU",
          submenu: ntrSubmenu
        });

        const institutionSubmenu = [];
        institutionSubmenu.push(
          {
            name: "Registration",
            translate: "sidebar.nav.SUBMENU",
            path: "/piscs"
          },
          {
            name: "Management",
            translate: "sidebar.nav.SUBMENU",
            path: "/management"
          }
        );

        Menu.push({
          name: "PSC DETAILS",
          icon: "icon-list",
          translate: "sidebar.nav.MENU",
          submenu: institutionSubmenu
        });

        Menu.push({
          name: "REPORTS",
          icon: "icon-chart",
          translate: "sidebar.nav.MENU",
          submenu: [
            {
              name: "BMS",
              translate: "sidebar.nav.SUBMENU",
              path: "/boardManagement"
            },
            {
              name: "FARS",
              translate: "sidebar.nav.SUBMENU",
              path: "/financialManagement"
            },
            {
              name: "OTR Portifolio",
              translate: "sidebar.nav.SUBMENU",
              path: "/portifolioManagement"
            },
            {
              name: "PSC Management",
              translate: "sidebar.nav.SUBMENU",
              path: "/pscManagement"
            }
          ]
        });

        const archiveSubmenu = [];
        archiveSubmenu.push({
          name: "Board Members",
          translate: "sidebar.nav.SUBMENU",
          path: "/previousBoards"
        },{
          name: "Management",
          translate: "sidebar.nav.SUBMENU",
          path: "/previousManagement"
        },
        {
          name: "Documents",
          translate: "sidebar.nav.SUBMENU",
          path: "/documents"
        });

        Menu.push({
          name: "ARCHIVE",
          icon: "icon-chart",
          translate: "sidebar.nav.MENU",
          submenu: archiveSubmenu
        });

        this.setState({
          userMenu: Menu
        });

      }else if(this.props.token[0].role_name.toLowerCase() === "pisc admin") {
        Menu.push({
          name: "DASHBOARD",
          icon: "icon-speedometer",
          path: "/home"
        }); 

        Menu.push({
          name: "SYSTEM USERS",
          icon: "icon-people",
          translate: "sidebar.nav.MENU",
          path: "/users/user"
        });

        Menu.push({
          name: "BOARD MEMBERS",
          icon: "icon-list",
          translate: "sidebar.nav.SUBMENU",
          path: "/appointments"
        });

        Menu.push({
          name: "INSTITUTIONS",
          icon: "icon-list",
          translate: "sidebar.nav.MENU",
          submenu: [
            {
              name: "Details",
              translate: "sidebar.nav.SUBMENU",
              path: "/pisc_establishment" 
            },
            // {
            //   name: "Branch Offices",
            //   translate: "sidebar.nav.SUBMENU",
            //   path: "/pisc_branches"
            // },
            {
              name: "Addresses",
              translate: "sidebar.nav.SUBMENU",
              path: "/pisc_addresses"
            }
          ]
        });

        this.setState({
          userMenu: Menu
        });

      }else{

        this.props.token.map(module => {
            if(module != null && module.module_name != null) {
              modules.push(module.module_name.toLowerCase());
            }
        });

        if(this.props.token[0].user_firstLogin === 1) {
            if(this.props.token[0].user_pisc_id === 1){
              Menu.push({
                name: "DASHBOARD",
                icon: "icon-speedometer",
                path: "/mainDashboard"
              }); 
            }else if(this.props.token[0].user_pisc_id === 0){
              Menu.push({
                name: "DASHBOARD",
                icon: "icon-speedometer",
                path: "/official-dashboard"
              }); 
            }else{
              Menu.push({
                name: "DASHBOARD",
                icon: "icon-speedometer",
                path: "/home"
              }); 
            }        

            if (modules.includes("financial statements")) {
              if(this.props.token[0].role_name.toLowerCase() == "dpim user" ||
                this.props.token[0].role_name.toLowerCase() == "dpim admin" ||
                this.props.token[0].role_name.toLowerCase() == "dpim"){
                  Menu.push({
                    name: "FARS",
                    icon: "icon-briefcase",
                    translate: "sidebar.nav.MENU",
                    submenu: [
                      {
                        name: "Quarterly reports",
                        translate: "sidebar.nav.MENU",
                        path: "/fars/quarterly_statements"
                      },
                      {
                        name: "Audited Reports",
                        translate: "sidebar.nav.MENU",
                        path: "/fars/audited_statements"
                      },
                      {
                        name: "Analysis",
                        translate: "sidebar.nav.MENU",
                        path: "/fars/financial-analysis"
                      }
                    ]
                  });

                  Menu.push({
                    name: "NTRC",
                    icon: "icon-briefcase",
                    translate: "sidebar.nav.MENU",
                    submenu: ntrSubmenu
                  });

                }else if(this.props.token[0].role_name.toLowerCase() == "data collector" ||
                this.props.token[0].role_name.toLowerCase() == "data verifier"){
                  Menu.push({
                    name: "FARS",
                    icon: "icon-briefcase",
                    translate: "sidebar.nav.MENU",
                    submenu: [
                      {
                        name: "Audited Reports",
                        translate: "sidebar.nav.MENU",
                        path: "/fars/audited_statements"
                      }
                    ]
                  });
                }else if(this.props.token[0].role_name.toLowerCase() == "plo - data collector" || 
                this.props.token[0].role_name.toLowerCase() == "plo - data verifier"){
                  Menu.push({
                    name: "FARS",
                    icon: "icon-briefcase",
                    translate: "sidebar.nav.MENU",
                    submenu: [
                      {
                        name: "Quarterly reports",
                        translate: "sidebar.nav.MENU",
                        path: "/fars/quarterly_statements"
                      }
                    ]
                  });
                }else if(this.props.token[0].role_name.toLowerCase() == "ceo"){
                  Menu.push({
                    name: "FARS",
                    icon: "icon-briefcase",
                    translate: "sidebar.nav.MENU",
                    submenu: [
                      {
                        name: "Quarterly reports",
                        translate: "sidebar.nav.MENU",
                        path: "/fars/quarterly_statements"
                      },
                      {
                        name: "Audited Reports",
                        translate: "sidebar.nav.MENU",
                        path: "/fars/audited_statements"
                      }
                    ]
                  });
                }else if(this.props.token[0].role_name.toLowerCase() == "ministry user"){
                  Menu.push({
                    name: "FARS",
                    icon: "icon-briefcase",
                    translate: "sidebar.nav.MENU",
                    submenu: [
                      {
                        name: "Analysis",
                        translate: "sidebar.nav.MENU",
                        path: "/fars/financial-analysis"
                      }
                    ]
                  });
                }else if(this.props.token[0].role_name.toLowerCase() == "tr"){
                  Menu.push({
                    name: "FARS",
                    icon: "icon-briefcase",
                    translate: "sidebar.nav.MENU",
                    submenu: [
                      {
                        name: "Quarterly reports",
                        translate: "sidebar.nav.MENU",
                        path: "/fars/quarterly_statements"
                      },
                      {
                        name: "Audited Reports",
                        translate: "sidebar.nav.MENU",
                        path: "/fars/audited_statements"
                      },
                      {
                        name: "Analysis",
                        translate: "sidebar.nav.MENU",
                        path: "/fars/financial-analysis"
                      }
                    ]
                  });
                }
            }

            if (modules.includes("representatives")) {
              Menu.push({
                name: "BMS",
                icon: "icon-user-following",
                translate: "sidebar.nav.MENU",
                submenu: [           
                  {
                    name: "Prospective Members",
                    translate: "sidebar.nav.SUBMENU",
                    path: "/appointee"
                  },
                  {
                    name: "Representatives",
                    translate: "sidebar.nav.SUBMENU",
                    path: "/representatives"
                  },
                  {
                    name: "Registration Status",
                    translate: "sidebar.nav.SUBMENU",
                    path: "/rep_progress"
                  }
                ]
              });
            }

            if (modules.includes("appointments")) {

              const boardSubmenu = [];
              
              //STARTING OF DMS MENU
              if (this.props.token[0].role_name.toLowerCase() === 'msd user'){
                  boardSubmenu.push({
                    name: "Prospective Members",
                    translate: "sidebar.nav.SUBMENU",
                    path: "/appointee"
                  },
                  {
                    name: "Board Members",
                    translate: "sidebar.nav.SUBMENU",
                    path: "/appointments"
                  },
                  {
                    name: "Registration Status",
                    translate: "sidebar.nav.SUBMENU",
                    path: "/appointmentProgress"
                  }
                );
              }

              if(this.props.token[0].role_name.toLowerCase() === 'dms' ||
                this.props.token[0].role_name.toLowerCase() === 'msd admin'){
                  boardSubmenu.push(
                    {
                      name: "Prospective Members",
                      translate: "sidebar.nav.SUBMENU",
                      path: "/appointee"
                    },
                    {
                      name: "Board Members",
                      translate: "sidebar.nav.SUBMENU",
                      path: "/appointments"
                    },
                    {
                      name: "Registration Status",
                      translate: "sidebar.nav.SUBMENU",
                      path: "/appointmentProgress"
                    }
                  );
              }

              if(this.props.token[0].role_name.toLowerCase() === 'tr' ||
                this.props.token[0].role_name.toLowerCase() === 'government official' ||
                this.props.token[0].role_name.toLowerCase() === 'ministry user'){
                  boardSubmenu.push(
                  // {
                  //   name: "Prospective Members",
                  //   translate: "sidebar.nav.SUBMENU",
                  //   path: "/appointee"
                  // },
                  {
                    name: "Board Members",
                    translate: "sidebar.nav.SUBMENU",
                    path: "/appointments"
                  },
                  {
                    name: "Representatives",
                    translate: "sidebar.nav.SUBMENU",
                    path: "/representatives"
                  },
                );
              }

              //STARTING OF PSC MENU
              if (this.props.token[0].role_name.toLowerCase() === 'board coordinator'){
                  boardSubmenu.push({
                    name: "Prospective Members",
                    translate: "sidebar.nav.SUBMENU",
                    path: "/appointee"
                  },
                  {
                    name: "Current Members",
                    translate: "sidebar.nav.SUBMENU",
                    path: "/appointments"
                  },
                  {
                    name: "Previous Members",
                    translate: "sidebar.nav.SUBMENU",
                    path: "/previousBoardMembers/"+ this.getEncodedUrl(this.props.token[0].user_pisc_id)
                  },
                  {
                    name: "Registration Status",
                    translate: "sidebar.nav.SUBMENU",
                    path: "/appointmentProgress"
                  }
                );
              }

              if(this.props.token[0].role_name.toLowerCase() === 'ahrm user' || 
                  this.props.token[0].role_name.toLowerCase() === 'ahrm admin' || 
                  this.props.token[0].role_name.toLowerCase() === 'data collector' || 
                  this.props.token[0].role_name.toLowerCase() === 'data verifier' ||
                  this.props.token[0].role_name.toLowerCase() === 'plo - data collector' || 
                  this.props.token[0].role_name.toLowerCase() === 'plo - data verifier' ||
                  this.props.token[0].role_name.toLowerCase() === 'ceo'){
                  boardSubmenu.push(
                  {
                    name: "Current Members",
                    translate: "sidebar.nav.SUBMENU",
                    path: "/appointments"
                  },
                  {
                    name: "Previous Members",
                    translate: "sidebar.nav.SUBMENU",
                    path: "/previousBoardMembers/"+ this.getEncodedUrl(this.props.token[0].user_pisc_id)
                  }
                );
              }

              Menu.push({
                name: "BMS",
                icon: "icon-briefcase",
                translate: "sidebar.nav.MENU",
                submenu: boardSubmenu
              });
            }

            // if(this.props.token[0].role_name.toLowerCase() === 'dpim user' || 
            //       this.props.token[0].role_name.toLowerCase() === 'dpim admin'){

            //     Menu.push({
            //       name: "NTRC",
            //       icon: "icon-briefcase",
            //       translate: "sidebar.nav.MENU",
            //       submenu: ntrSubmenu
            //     });
            // }

            if(modules.includes("pisc")) {

              const institutionSubmenu = [];

              if(this.props.token[0].role_name.toLowerCase() === 'tr' || 
                this.props.token[0].role_name.toLowerCase() === 'government official' || 
                  this.props.token[0].role_name.toLowerCase() === 'ministry user'){
                  
                    institutionSubmenu.push(
                      {
                        name: "PSCs",
                        translate: "sidebar.nav.SUBMENU",
                        path: "/piscs"
                      },
                      {
                        name: "Minority Interests",
                        translate: "sidebar.nav.SUBMENU",
                        path: "/minority_interests"
                      },
                      {
                        name: "Priv. Enterprises",
                        translate: "sidebar.nav.MENU",
                        path: "/privatized_enterprises"
                      },
                      // {
                      //   name: "Managements",
                      //   translate: "sidebar.nav.SUBMENU",
                      //   path: "/management"
                      // },
                      {
                        name: "PSC's Investments",
                        translate: "sidebar.nav.SUBMENU",
                        path: "/pisc_investments"
                      }
                    );

                    Menu.push({
                    name: "INSTITUTIONS",
                    icon: "icon-list",
                    translate: "sidebar.nav.MENU",
                    submenu: institutionSubmenu
                  });
                }

              //STARTING OF DPIM MENU
              if (this.props.token[0].role_name.toLowerCase() === 'dpim user' || 
                this.props.token[0].role_name.toLowerCase() === 'dpim admin' || 
                this.props.token[0].role_name.toLowerCase() === 'dpim'){
                  
                  institutionSubmenu.push(
                    {
                      name: "PSCs",
                      translate: "sidebar.nav.SUBMENU",
                      path: "/piscs"
                    },
                    {
                      name: "MIs",
                      translate: "sidebar.nav.SUBMENU",
                      path: "/minority_interests"
                    },
                    {
                      name: "PSC's Investments",
                      translate: "sidebar.nav.SUBMENU",
                      path: "/pisc_investments"
                    }
                  );

                  Menu.push({
                    name: "INSTITUTIONS",
                    icon: "icon-list",
                    translate: "sidebar.nav.MENU",
                    submenu: institutionSubmenu
                  });
                }

                //STARTING OF MSD MENU
                if(this.props.token[0].role_name.toLowerCase() === 'msd user' || 
                this.props.token[0].role_name.toLowerCase() === 'msd admin' || 
                  this.props.token[0].role_name.toLowerCase() === 'dms'){
                  
                    institutionSubmenu.push(
                      {
                        name: "Registration",
                        translate: "sidebar.nav.SUBMENU",
                        path: "/piscs"
                      },
                      {
                        name: "Management",
                        translate: "sidebar.nav.SUBMENU",
                        path: "/management"
                      }
                    );

                    Menu.push({
                    name: "PSC DETAILS",
                    icon: "icon-list",
                    translate: "sidebar.nav.MENU",
                    submenu: institutionSubmenu
                  });
                }

                //STARTING OF DPMERD MENU
                if(this.props.token[0].role_name.toLowerCase() === 'dpmerd user' || 
                  this.props.token[0].role_name.toLowerCase() === 'dpmerd admin' || 
                  this.props.token[0].role_name.toLowerCase() === 'dpmerd'){
                  Menu.push({
                    name: "PE DETAILS",
                    icon: "icon-list",
                    translate: "sidebar.nav.MENU",
                    path: "/privatized_enterprises"
                  });
                }

                //STARTING OF PSC MENU
                if (this.props.token[0].role_name.toLowerCase() === 'board coordinator' ||
                  this.props.token[0].role_name.toLowerCase() === 'ahrm user' || 
                  this.props.token[0].role_name.toLowerCase() === 'ahrm admin' || 
                  this.props.token[0].role_name.toLowerCase() === 'data collector' || 
                  this.props.token[0].role_name.toLowerCase() === 'data verifier' ||
                  this.props.token[0].role_name.toLowerCase() === 'plo - data collector' || 
                  this.props.token[0].role_name.toLowerCase() === 'plo - data verifier' ||
                  this.props.token[0].role_name.toLowerCase() === 'ceo'){
                    institutionSubmenu.push(
                      {
                        name: "Institution Details",
                        translate: "sidebar.nav.SUBMENU",
                        path: "/pisc_establishment" 
                      },
                      // {
                      //   name: "Management",
                      //   translate: "sidebar.nav.SUBMENU",
                      //   path: "/organogram/"+ this.getEncodedUrl(this.props.token[0].user_pisc_id)
                      // },
                      {
                        name: "Contacts",
                        translate: "sidebar.nav.SUBMENU",
                        path: "/pisc_addresses"
                      }
                    );

                  Menu.push({
                    name: "PSC DETAILS",
                    icon: "icon-list",
                    translate: "sidebar.nav.MENU",
                    submenu: institutionSubmenu
                  });
                }
            }
            if(modules.includes("settings")){
              Menu.push({
                name: "SETTINGS",
                icon: "icon-settings",
                translate: "sidebar.nav.MENU",
                submenu: [
                  {
                    name: "Subsidiaries",
                    translate: "sidebar.nav.SUBMENU",
                    path: "/companies"
                  },
                  {
                    name: "Equity Issuers",
                    translate: "sidebar.nav.SUBMENU",
                    path: "/equity-issuers"
                  },
                  {
                    name: "JV Companies",
                    translate: "sidebar.nav.SUBMENU",
                    path: "/joint-ventures"
                  },
                  // {
                  //   name: "JV Partners",
                  //   translate: "sidebar.nav.SUBMENU",
                  //   path: "/templates"
                  // }
                ]
              });
            }

            if (modules.includes("reports")) {
              Menu.push({
                name: "REPORTS",
                icon: "icon-chart",
                translate: "sidebar.nav.MENU",
                submenu: [
                  {
                    name: "BMS",
                    translate: "sidebar.nav.SUBMENU",
                    path: "/boardManagement"
                  },
                  {
                    name: "FARS",
                    translate: "sidebar.nav.SUBMENU",
                    path: "/financialManagement"
                  },
                  {
                    name: "OTR Portifolio",
                    translate: "sidebar.nav.SUBMENU",
                    path: "/portifolioManagement"
                  },
                  {
                    name: "PSC Management",
                    translate: "sidebar.nav.SUBMENU",
                    path: "/pscManagement"
                  }
                ]
              });
            }

            if (modules.includes("archive")) {

              const archiveSubmenu = [];

              if(this.props.token[0].role_name.toLowerCase() === 'board coordinator'){
                archiveSubmenu.push({
                  name: "Uploading",
                  translate: "sidebar.nav.SUBMENU",
                  path: "/upload"
                },
                {
                  name: "Board Documents",
                  translate: "sidebar.nav.SUBMENU",
                  path: "/piscDocuments/"+ this.getEncodedUrl(this.props.token[0].user_pisc_id)
                });
              }
              if(this.props.token[0].role_name.toLowerCase() === 'ceo'){
                archiveSubmenu.push({
                  name: "Board Documents",
                  translate: "sidebar.nav.SUBMENU",
                  path: "/piscDocuments/"+ this.getEncodedUrl(this.props.token[0].user_pisc_id)
                });
              }

              if(this.props.token[0].role_name.toLowerCase() === 'tr' ||
                this.props.token[0].role_name.toLowerCase() === 'government official' ||
                this.props.token[0].role_name.toLowerCase() === 'ministry user' || 
                this.props.token[0].role_name.toLowerCase() === 'msd user' ||
                this.props.token[0].role_name.toLowerCase() === 'msd admin' || 
                this.props.token[0].role_name.toLowerCase() === 'dms' ||
                this.props.token[0].role_name.toLowerCase() === 'dpim user' ||
                this.props.token[0].role_name.toLowerCase() === 'dpim admin' || 
                this.props.token[0].role_name.toLowerCase() === 'dpim'){
                archiveSubmenu.push({
                  name: "Board Members",
                  translate: "sidebar.nav.SUBMENU",
                  path: "/previousBoards"
                },{
                  name: "Management",
                  translate: "sidebar.nav.SUBMENU",
                  path: "/previousManagement"
                });
                // {
                //   name: "Documents",
                //   translate: "sidebar.nav.SUBMENU",
                //   path: "/documents"
                // });
              }

              //Menu.push({
              //  name: "ARCHIVE",
              //  icon: "icon-chart",
              //  translate: "sidebar.nav.MENU",
              //  submenu: archiveSubmenu
              //});
            }

            // if(modules.includes("user manuals")){
            //   Menu.push({
            //     name: "SYSTEM MANUALS",
            //     icon: "icon-list",
            //     translate: "sidebar.nav.MENU",
            //     path: '/userManual'
            //   });
            // }
        }


        // BOARD MEMBER SIDEBAR

        if(this.props.token[0].role_name.toLowerCase() === 'board member') {
          Menu.push({
            name: "HOME",
            icon: "icon-user",
            path: "/member_profile/"+ this.getEncodedUrl(this.props.token[0].id) +"/0"
          });

          Menu.push({
              name: "REG. STATUS",
              icon: "icon-list",
              path: "/appointmentProgress"
          });
          // Menu.push({
          //   name: "LOGOUT",
          //   icon: "icon-logout",
          //   path: "/logout"
          // });

          this.setState({
            userMenu: Menu
          });
        }

      }

      Menu.push({
        name: "LOGOUT",
        icon: "icon-logout",
        path: "/logout"
      });

      this.setState({
        userMenu: Menu
      });

    }else{
      
      this.setState ({
        //redirect : true
      })
    }

    // pass navigator to access router api
    SidebarRun(this.navigator, this.closeSidebar);
    // prepare the flags to handle menu collapsed states
    this.buildCollapseList();

    // Listen for routes changes in order to hide the sidebar on mobile
    this.props.history.listen(this.closeSidebar);
  }

  closeSidebar = () => {
    this.props.actions.toggleSetting("asideToggled");
  };

  /** prepare initial state of collapse menus. Doesnt allow same route names */
  buildCollapseList = () => {
    let collapse = {};
    this.state.userMenu
      .filter(({ heading }) => !heading)
      .forEach(({ name, path, submenu }) => {
        collapse[name] = this.routeActive(
          submenu ? submenu.map(({ path }) => path) : path
        );
      });
    this.setState({ collapse });
  };

  navigator = route => {
    this.props.history.push(route);
  };

  routeActive(paths) {
    paths = Array.isArray(paths) ? paths : [paths];
    return paths.some(p => this.props.location.pathname.indexOf(p) > -1);
  }

  toggleItemCollapse(stateName) {
    for (let c in this.state.collapse) {
      if (this.state.collapse[c] === true && c !== stateName)
        this.setState({
          collapse: {
            [c]: false
          }
        });
    }
    this.setState({
      collapse: {
        [stateName]: !this.state.collapse[stateName]
      }
    });
  }

  getSubRoutes = item => item.submenu.map(({ path }) => path);

  /** map menu config to string to determine which element to render */
  itemType = item => {
    if (item.heading) return "heading";
    if (!item.submenu) return "menu";
    if (item.submenu) return "submenu";
  };

  render() {
    /** Component to display headings on sidebar */
    const SidebarItemHeader = ({ item }) => (
      <li className="nav-heading">
        <span>
          <Trans i18nKey={item.translate}>{item.heading}</Trans>
        </span>
      </li>
    );

    /** Normal items for the sidebar */
    const SidebarItem = ({ item, isActive }) => (
      <li className={isActive ? "active" : ""}>
        <Link to={item.path} title={item.name}>
          {item.label && (
            <Badge tag="div" className="float-right" color={item.label.color}>
              {item.label.value}
            </Badge>
          )}
          {item.icon && <em className={item.icon}></em>}
          <span>
            <Trans i18nKey={item.translate}>{item.name}</Trans>
          </span>
        </Link>
      </li>
    );

    /** Build a sub menu with items inside and attach collapse behavior */
    const SidebarSubItem = ({ item, isActive, handler, children, isOpen }) => (
      <li className={isActive ? "active" : ""}>
        <div className="nav-item" onClick={handler}>
          {item.label && (
            <Badge tag="div" className="float-right" color={item.label.color}>
              {item.label.value}
            </Badge>
          )}
          {item.icon && <em className={item.icon}></em>}
          <span>
            <Trans i18nKey={item.translate}>{item.name}</Trans>
          </span>
        </div>
        <Collapse isOpen={isOpen}>
          <ul id={item.path} className="sidebar-nav sidebar-subnav">
            {children}
          </ul>
        </Collapse>
      </li>
    );

    /** Component used to display a header on menu when using collapsed/hover mode */
    const SidebarSubHeader = ({ item }) => (
      <li className="sidebar-subnav-header">{item.name}</li>
    );

    return (
      <aside className="aside-container">
        {/* {this.state.redirect && <Redirect to="/logout" />} */}
        {/* START Sidebar (left) */}
        <div className="aside-inner">
          <nav data-sidebar-anyclick-close="" className="sidebar">
            {/* START sidebar nav */}
            <ul className="sidebar-nav">
              {/* START user info */}
              <li className="has-user-block">
                <SidebarUserBlock />
              </li>
              {/* END user info */}

              {/* Iterates over all sidebar items */}
              {this.state.userMenu.map((item, i) => {
                // heading
                if (this.itemType(item) === "heading")
                  return <SidebarItemHeader item={item} key={i} />;
                else {
                  if (this.itemType(item) === "menu")
                    return (
                      <SidebarItem
                        isActive={this.routeActive(item.path)}
                        item={item}
                        key={i}
                      />
                    );
                  if (this.itemType(item) === "submenu")
                    return [
                      <SidebarSubItem
                        item={item}
                        isOpen={this.state.collapse[item.name]}
                        handler={this.toggleItemCollapse.bind(this, item.name)}
                        isActive={this.routeActive(this.getSubRoutes(item))}
                        key={i}
                      >
                        <SidebarSubHeader item={item} key={i} />
                        {item.submenu.map((subitem, i) => (
                          <SidebarItem
                            key={i}
                            item={subitem}
                            isActive={this.routeActive(subitem.path)}
                          />
                        ))}
                      </SidebarSubItem>
                    ];
                }
                return null; // unrecognized item
              })}
            </ul>
            {/* END sidebar nav */}
          </nav>
        </div>
        {/* END Sidebar (left) */}
      </aside>
    );
  }
}

Sidebar.propTypes = {
  actions: PropTypes.object,
  userModules: PropTypes.object.isRequired,
  settings: PropTypes.object,
  token: PropTypes.object.isRequired,
  
};

const mapStateToProps = state => ({
  userModules: state.userModules.items,
  settings: state.settings,
  user_token: state.token.user_token,
  token: state.token.userData,
  
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("translations")(withRouter(Sidebar)));
